import http from '@/axios'
import { IDashboard } from '@/interfaces/BaseInterface';
import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_OBJECT = AuthService.getHybrainTokenObject();
const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString();

class Dashboard implements IDashboard {

    fetchTotalUsers(data: any) {
        return http.get(`dashboards/total-users?${TOKEN_PARAM_STRING}&${data}`);
    }

    fetchLatestBookings(data: any) {
        return http.get(`dashboards/latest-bookings?${TOKEN_PARAM_STRING}&${data}`);
    }
    
    fetchBookingCalendar(data: object) {
        return http.post(`dashboards/booking-calendar`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    fetchDateBookings(data: string) {
        return http.get(`dashboards/date-bookings?${TOKEN_PARAM_STRING}&${data}`);
    }
}

export default new Dashboard();
