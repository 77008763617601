import http from '@/axios'
import { IRolePermission } from '@/interfaces/BaseInterface';

class RolePermissionService implements IRolePermission {
    
    indexRole(data: any) {
        return http.get(`role-permissions/index?${data}`);
    }

    showRole(id: string) {
        return http.get(`role-permissions/show-role/${id}`);
    }

    showPermissions(id: string) {
        return http.get(`role-permissions/show-permissions/${id}`);
    }
}

export default new RolePermissionService();
