import http from '@/axios'
import Storage from '@/helpers/storage';
import { computed } from 'vue';
import { IAuth } from '@/interfaces/BaseInterface';

class AuthService implements IAuth {
    private readonly storage;

    public constructor(getStorage = () => new Storage()) {
        this.storage = getStorage();
      }

    async login(email: string, password: string) {
        return http
            .post("auth/login", {
                email,
                password
            })
    }

    async forgotPassword(email: string) {
        return http
            .post("auth/forgot-password", {
                email,
            })
    }

    async changePassword(payload: object) {
        return http
            .post("auth/change-password", payload)
    }

    async logout() {
        return http
            .post("auth/logout")
            .then(() => {
                this.storage.clearItems(['admin', 'access_token', 'hybrain_token', 'permissions'])
                window.location.replace('/login')
            })
    }

    isAuthenticated() {
        const authToken = computed(() => this.storage.get('access_token'));
        return authToken.value ? true : false;
    }

    redirectToLogin() {
        this.storage.clearItems(['admin', 'hybrain_token', 'access_token', 'permissions'])
        window.location.replace('/login')
    }

    // For POST request
    getHybrainTokenObject() : { token: string | null } {
        const getToken = this.storage.get("hybrain_token");
        return {
            token: getToken
        }
    }

    // For GET request
    getHybrainTokenQueryString() : string {
        const getToken = this.storage.get("hybrain_token");
        return `token=${getToken}`;
    }
}
export default new AuthService();