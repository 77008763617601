import { formatInTimeZone } from 'date-fns-tz'

const pattern = 'hh:mm a';
    
const timeZone = "UTC";

const formateTimeSlot = (start:string, end:string):string => {
    start = new Date(start).toLocaleTimeString([], { timeZone: timeZone, hour: '2-digit', minute:'2-digit' })
    end = new Date(end).toLocaleTimeString([], { timeZone: timeZone, hour: '2-digit', minute:'2-digit' })
    
    return `${start} - ${end}`
}

export {
    pattern,
    timeZone,
    formateTimeSlot,
};