/* eslint-disable */

const paginatePage = (data: string): string => {
    const urlParams = new URL(data ?? '', 'https://example.com');
    const searchParams = urlParams.searchParams;
    const page = searchParams.get('page') ?? '1';
    return `page=${page}`;
};

const checkForInvalidResponse = (error: any, redirectRoute: string) => {
    if(error.response?.status == 422) {
      setTimeout(() => {
          window.location.assign(redirectRoute)
      }, 1000);
  }
}

export { paginatePage, checkForInvalidResponse }