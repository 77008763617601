import http from '@/axios'
import { ICompany } from '@/interfaces/BaseInterface';

class Company implements ICompany {
    
    indexCompany(branchId: string, queryParams: string) {
        return http.get(`companies/index/${branchId}?${queryParams}`);
    }

    createCompany() {
        return http.get(`companies/create`);
    }

    storeCompany(payload: object) {
        return http.post(`companies/store`, payload);
    }

    getSelectedCompany(id: string) {
        return http.get(`companies/edit/` + id);
    }

    updateCompany(companyId: string, payload: object) {
        return http.post(`companies/update/${companyId}`, payload);
    }

    deleteCompany(id: string) {
        return http.delete(`companies/delete/${id}`);
    }

    restoreCompany(id: string) {
        return http.post(`companies/restore`, {id: id});
    }

    exportCompany(payload: string) {
        return http.get(`companies/export?${payload}`);
    }

    importCompany(payload: object) {
        return http.post(`companies/import`, payload);
    }

}

export default new Company();
