import http from '@/axios'
import { IAdmin } from '@/interfaces/BaseInterface';
import Storage from '@/helpers/storage';

class AdminData implements IAdmin {

    getAdmin() {
        const storage = new Storage()
        const admin = storage.get("admin");
        if (admin) return JSON.parse(admin);
        return null;
    }

    getRoles() {
        return http.get(`admins/roles`);
    }

    indexAdmin(data: any) {
        return http.get(`admins/index?${data}`);
    }

    editAdmin(id: string) {
        return http.get(`admins/edit/${id}`);
    }

    storeAdmin(payload: object) {
        return http.post(`admins/store`, payload);
    }

    updateAdmin(adminId: string, payload: object) {
        return http.post(`admins/update/${adminId}`, payload);
    }

    deleteAdmin(id: string) {
        return http.delete(`admins/delete/${id}`);
    }

    restoreAdmin(id: string) {
        return http.post(`admins/restore`, {id: id});
    }

    importAdmin(payload: object) {
        return http.get(`admins/import?${payload}`);
    }

    exportAdmin(payload: string) {
        return http.get(`admins/export?${payload}`);
    }

    tokenlessChangePassword(payload: object) {
        return http.post(`auth/tokenless-change-password`, payload);
    }

    changePassword(payload: object) {
        return http.post(`auth/change-password`, payload);
    }
}

export default new AdminData();
