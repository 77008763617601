import http from '@/axios'
import { IEmailNotification } from '@/interfaces/BaseInterface';

class EmailNotification implements IEmailNotification {
    
    indexEmailNotification(data: any) {
        return http.get(`email-notifications/index?${data}`);
    }
    
    editEmailNotification(id: string) {
        return http.get(`email-notifications/edit/${id}`);
    }

    updateEmailNotification(emailNotificationId: string, payload: object) {
        return http.post(`email-notifications/update/${emailNotificationId}`, payload);
    }
}

export default new EmailNotification();
