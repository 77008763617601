import http from '@/axios'
import { IBlockedDate } from '@/interfaces/BaseInterface';

class BlockedDate implements IBlockedDate {
    
    indexBlockedDate(branchId: string, data: any) {
        return http.get(`blocked-dates/index/${branchId}?${data}`);
    }

    createBlockedDate() {
        return http.get(`blocked-dates/create`);
    }

    storeBlockedDate(payload: object) {
        return http.post(`blocked-dates/store`, payload);
    }
    
    editBlockedDate(id: string) {
        return http.get(`blocked-dates/edit/${id}`);
    }

    updateBlockedDate(blockedDateId: string, payload: object) {
        return http.post(`blocked-dates/update/${blockedDateId}`, payload);
    }

    deleteBlockedDate(id: string) {
        return http.delete(`blocked-dates/delete/${id}`);
    }

    restoreBlockedDate(id: string) {
        return http.post(`blocked-dates/restore`, {id: id});
    }

    exportBlockedDate(payload: string) {
        return http.get(`blocked-dates/export?${payload}`);
    }
}

export default new BlockedDate();
