import http from '@/axios'
import { IRiders } from '@/interfaces/BaseInterface';
import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString()

class Riders implements IRiders {
    
    indexRiders(data: any) {
        return http.get(`riders/index?${data}&${TOKEN_PARAM_STRING}`);
    }
    
    showRider(name: string) {
        return http.get(`riders/edit?name=${name}&${TOKEN_PARAM_STRING}`);
    }

    showRiderSchedule(name: string) {
        return http.get(`riders/schedule/show?rider_name=${name}&${TOKEN_PARAM_STRING}`);
    }

    updateRiderSchedule(payload: any) {
        return http.post(`riders/schedule`, payload);
    }
}

export default new Riders();
