interface IdaysOfWeek {
    label: string,
    value: number | string,
    start_time?: Date,
    end_time?: Date,
}

const daysOfTheWeek : IdaysOfWeek[] = [
    {
        label: 'Sunday',
        value: 0
    },
    {
        label: 'Monday',
        value: 1
    },
    {
        label: 'Tuesday',
        value: 2
    },
    {
        label: 'Wednesday',
        value: 3
    },
    {
        label: 'Thursday',
        value: 4
    },
    {
        label: 'Friday',
        value: 5
    },
    {
        label: 'Saturday',
        value: 6
    },
   
]

const replaceDashWithBackslash = (str: string) : string => {
    return str.replace(/-/g, '/');
}

const viewID = (base64:string|null):void =>{
    if(base64){
        base64 = base64.replace('data:image/png;base64,', '');
        const mime:any = getMimeType(base64);
        const decodedString = atob(base64);
        const uint8Array = Uint8Array.from([...decodedString].map(char => char.charCodeAt(0)));
        const blob = new Blob([uint8Array], { type: mime });
        const testResultFile = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = testResultFile;
        link.target = '_blank';
        link.click();
    }
}

const getMimeType = (base64:string):string|null => {
    const signatures:any = {
        JVBERi0: "application/pdf",
        R0lGODdh: "image/gif",
        R0lGODlh: "image/gif",
        iVBORw0KGgo: "image/png",
        "/9j/": "image/jpg"
    };


    for (const index in signatures) {
        if (base64.indexOf(index) === 0) {
            return signatures[index];
        }
    }
    
    return null;
}


export { daysOfTheWeek, replaceDashWithBackslash, viewID }