import http from '@/axios'
import { IAdminNotification } from '@/interfaces/BaseInterface';

class AdminNotification implements IAdminNotification {
    
    indexAdminNotification() {
        return http.get(`notifications/index`);
    }
    
    readAdminNotification(id: string) {
        return http.post(`notifications/read/${id}`);
    }

    readAllAdminNotification() {
        return http.post(`notifications/readAll`);
    }
}

export default new AdminNotification();
