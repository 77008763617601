<template>
    <div>
        <form action="#" class="mb-8">
            <div class="w-full mx-auto">
                <div class="flex space-x-2">
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num1"
                        ref="otp1" 
                        @keyup="moveFocus($event, $refs.otp2.$el, '')" 
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num2"
                        ref="otp2"
                        @keyup="moveFocus($event, $refs.otp3.$el, $refs.otp1.$el)" 
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num3"
                        ref="otp3"
                        @keyup="moveFocus($event, $refs.otp4.$el, $refs.otp2.$el)" 
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num4" 
                        ref="otp4"
                        @keyup="moveFocus($event, $refs.otp5.$el, $refs.otp3.$el)"
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num5" 
                        ref="otp5"
                        @keyup="moveFocus($event, $refs.otp6.$el, $refs.otp4.$el)"
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num6" 
                        ref="otp6"
                        placeholder="_" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                </div>
            </div>
        </form>
        <div class="text-center">
            <p class="text-sm text-primary-400 mb-2">Didn’t get a code?</p>
            <p @click="resendOtp" class="text-sm font-semibold text-primary-100">Resend in {{ timerCount }}s</p>

            <button-component
                size="default"
                color="primary"
                expand="block"
                href="/reset-password"
                class="mt-6"
            >
                <template #content>
                    Verify
                </template>
            </button-component>
        </div>
    </div>
</template>

<script>
import { 
    IonInput 
} from '@ionic/vue';

// Components
import ButtonComponent from '@/components/ButtonComponent.vue';

import { useRouter } from 'vue-router';
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'OTPPage',
    components: { 
        IonInput,
        ButtonComponent
    },
    props: {
        mobile: {
            type: String,
        },
        validateUrl: {
            type: String,
        },
        resendUrl: {
            type: String,
        },
        email: {
            type: String,
        },
        value: {
            type: String,
        },
        code: {
            type: Number,
        }
    },
    watch: {

        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    setup() {
        const loading = ref(false);
        // const message = ref(null);
        const router = useRouter();
        // const title = ref(null);
        // const store = useUserStore();
        const timerCount = ref(130);
        const timerEnabled = ref(true);

        const otpNum = [
            { num1: ref(null)},
            { num2: ref(null)},
            { num3: ref(null)},
            { num4: ref(null)},
            { num5: ref(null)},
            { num6: ref(null)}
        ];

        const moveFocus = (event, nextElement, previousElement) => {
            if (event.keyCode == 8 && previousElement) {
                previousElement.setFocus();
            } else if (event.keyCode >= 48 && event.keyCode <= 57) {
                if (nextElement) {
                    nextElement.setFocus();
                }
            } else {
                event.path[0].value = "";
            }
        };
        
        return {
            loading,
            otpNum,
            router,
            // validate,
            // resendOtp,
            timerCount,
            timerEnabled,
            moveFocus
        }
    }
});
</script>
