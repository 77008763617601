import http from '@/axios'
import { ISetting } from '@/interfaces/BaseInterface';

class Setting implements ISetting {
    
    indexSetting() {
        return http.get(`settings/index`);
    }
    
    editSetting(id: string) {
        return http.get(`settings/edit/${id}`);
    }

    updateSetting(reservationCapacityId: string, payload: object) {
        return http.post(`settings/update/${reservationCapacityId}`, payload);
    }
}

export default new Setting();
