import http from '@/axios'
import { IHmoAccreditations } from '@/interfaces/BaseInterface';
import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString()

class HmoAccreditations implements IHmoAccreditations {
    
    indexHmo(data: any) {
        return http.get(`hmo/index?${data}&${TOKEN_PARAM_STRING}`);
    }
    
    showHmo(id: string) {
        return http.get(`hmo/show?hmoId=${id}&${TOKEN_PARAM_STRING}`);
    }
}

export default new HmoAccreditations();
