import http from '@/axios'
import { IPatient } from '@/interfaces/BaseInterface';
import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString()
const TOKEN_PARAM_OBJECT = AuthService.getHybrainTokenObject()

class Patient implements IPatient {
    
    indexPatient(data: any) {
        return http.get(`patients/index?${data}&${TOKEN_PARAM_STRING}`);
    }
    
    showPatient(id: string) {
        return http.get(`patients/edit/${id}?${TOKEN_PARAM_STRING}`);
    }

    updatePatient(payload: any) {
        return http.post(`patients/update`, Object.assign(payload, TOKEN_PARAM_OBJECT));
    }

    exportPatient(payload: string) {
        return http.get(`patients/export?${payload}&${TOKEN_PARAM_STRING}`);
    }

    showPatientBookings(id: string, data: any) {
        return http.get(`patients/bookings/index/${id}?${data}&${TOKEN_PARAM_STRING}`);
    }

    showSingleBooking(id: string) {
        return http.get(`patients/booking/show/${id}?${TOKEN_PARAM_STRING}`);
    }

    showMedicalRecord(id: string) {
        return http.get(`patients/medical-record/show/${id}?${TOKEN_PARAM_STRING}`);
    }
}

export default new Patient();
