import http from '@/axios'
import { IIllnessCondition } from '@/interfaces/BaseInterface';

class IllnessCondition implements IIllnessCondition {
    
    indexIllnessCondition(data: any) {
        return http.get(`illness-conditions/index?${data}`);
    }

    createIllnessCondition() {
        return http.get(`illness-conditions/create`);
    }

    storeIllnessCondition(payload: object) {
        return http.post(`illness-conditions/store`, payload);
    }
    
    editIllnessCondition(id: string) {
        return http.get(`illness-conditions/edit/${id}`);
    }

    updateIllnessCondition(illnessConditionId: string, payload: object) {
        return http.post(`illness-conditions/update/${illnessConditionId}`, payload);
    }

    deleteIllnessCondition(id: string) {
        return http.delete(`illness-conditions/delete/${id}`);
    }

    restoreIllnessCondition(id: string) {
        return http.post(`illness-conditions/restore/`, {id: id});
    }

    exportIllnessCondition(payload: string) {
        return http.get(`illness-conditions/export?${payload}`);
    }

    importIllnessCondition(payload: object) {
        return http.post(`illness-conditions/import`, payload);
    }

    reorderIllnessCondition(payload: object) {
        return http.post(`illness-conditions/reorder`, payload);
    }
}

export default new IllnessCondition();
