import http from '@/axios'
import { ISystemNotification } from '@/interfaces/BaseInterface';

class SystemNotification implements ISystemNotification {
    
    indexSystemNotification(data: any) {
        return http.get(`system-notifications/index?${data}`);
    }
    
    editSystemNotification(id: string) {
        return http.get(`system-notifications/edit/${id}`);
    }

    updateSystemNotification(systemNotificationId: string, payload: object) {
        return http.post(`system-notifications/update/${systemNotificationId}`, payload);
    }
}

export default new SystemNotification();
