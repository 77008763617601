import { defineStore } from 'pinia'

interface INetworkState {
    isNetworkAvailable: boolean
}

export const useNetworkStore = defineStore({
    id: 'network',
    state: (): INetworkState => {
        return {
            isNetworkAvailable: true,
        }
    },
    getters: {},
    actions: {},
})