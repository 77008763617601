import http from '@/axios'
import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString()

import { 
    PagePayloadInterface 
} from '@/interfaces/BaseInterface';

class Pages {
    fetch() {
        return http.get(`pages/fetch?&${TOKEN_PARAM_STRING}`);
    }
    
    show(id: number) {
        return http.get(`pages/show/${id}?${TOKEN_PARAM_STRING}`);
    }

    update(id: number, payload: PagePayloadInterface) {
        return http.post(`pages/update/${id}?${TOKEN_PARAM_STRING}`, payload);
    }
}

export default new Pages();
