import http from '@/axios'

class LocationService {

    fetchRegions() : Promise<JSON> {
        return http.get(`locations/regions`);
    }

    fetchProvinces(params: string) : Promise<JSON> {
        return http.get(`locations/province/search?${params}`);
    }

    fetchCities(params: string) : Promise<JSON> {
        return http.get(`locations/cities?${params}`);
    }

    fetchBarangays(params: string) : Promise<JSON> {
        return http.get(`locations/barangay?${params}`);
    }

    clearAddress(level:string, form: any) {
        switch (level) {
            case 'province':
                form.province_code = null;
                // fall through to clear lower levels
            case 'city':
                form.city_code = null;
                // fall through to clear lower level
            case 'barangay':
                form.barangay_code = null;
                break;
            default:
                throw new Error(`Unknown address level: ${level}`);
        }
    }

    async fetchLocationData (serviceFunction: any, code: string, level: string, data: any) {
        const result = await serviceFunction(`code=${code}&level=${level}`)
        data.value = result?.data
    }
}

export default new LocationService();
