import http from '@/axios'
import { IBranchBooking } from '@/interfaces/BaseInterface';
import AuthService from "@/classes/AuthService";

// const TOKEN_PARAM_OBJECT = AuthService.getHybrainTokenObject()
const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString()

class BranchBooking implements IBranchBooking {
    
    indexBranchBooking(data: any) {
        return http.get(`branch-bookings/index?${TOKEN_PARAM_STRING}&${data}`);
    }

    createBranchBooking() {
        return http.get(`branch-bookings/create`);
    }

    storeBranchBooking() {
        return http.get(`branch-bookings/store`);
    }

    editBranchBooking() {
        return http.get(`branch-bookings/edit`);
    }

    updateBranchBooking() {
        return http.get(`branch-bookings/update`);
    }
}

export default new BranchBooking();
