/* import './offline.ts'; */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createPinia } from 'pinia';
import { IonicVue } from '@ionic/vue';
import * as Sentry from "@sentry/vue";

/* Database */
/* import '@capacitor-community/sqlite'; */

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Custom Font */
import './assets/fonts/nunito/stylesheet.scss';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';

/* Custom CSS */
import './assets/scss/app.scss';

import Storage from '@/helpers/storage';
import { Network } from '@capacitor/network';
import { useNetworkStore } from '@/store/network';

declare module 'vue' {
  interface ComponentCustomProperties {
    $hasPermission: (...permissionNames: Array<string>) => boolean;
    $isNetworkAvailable: boolean;
  }
}

declare global {
    interface Window {
        $hasPermission: (...permissionNames: Array<string>) => boolean;
        $isNetworkAvailable: boolean;
    }
}

const storage = new Storage();
const pinia = createPinia();

const permissions = {
  install: (app: any) => {
      window.$hasPermission = app.config.globalProperties.$hasPermission = (
          ...permissionNames: Array<string>
      ) => {
        const stringifiedPermissions = storage.get('permissions') as unknown as string;

        const adminPermissions = storage.get('permissions') ? JSON.parse(stringifiedPermissions) : [];

          if (adminPermissions) {
              return adminPermissions.some(
                  (permission: string) => permissionNames.includes(permission)
              );
  
          } else {
              return false;
          }
      };
  },
};

const app = createApp(App)
  .use(IonicVue, {
    mode: 'ios',
  })
  .use(pinia)
  .use(permissions);

// Sentry.init({
//   app,
//   dsn: "https://0d601385dc5e4201bdfdd6f0d8f38b39@sentry.praxxys.ph/55",
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: [process.env.VUE_SENTRY_TRACING_ORIGIN_URL, /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

app.use(router);
  
router.isReady().then(() => {
  logCurrentNetworkStatus();
  addNetworkListener();
  app.mount('#app');
});

const networkStore = useNetworkStore();

const logCurrentNetworkStatus = async () => {
  const status = await Network.getStatus();
  console.log('Network status:', status);
  storage.set('isNetworkAvailable', JSON.stringify(status.connected))
  networkStore.isNetworkAvailable = storage.get('isNetworkAvailable') == 'true' ? true : false;
};

const addNetworkListener = () => {
  Network.addListener('networkStatusChange', status => {
    console.log('Network status changed', status);
    storage.set('isNetworkAvailable', JSON.stringify(status.connected))
    networkStore.isNetworkAvailable = storage.get('isNetworkAvailable') == 'true' ? true : false;
  });
}

if ("serviceWorker" in navigator) {
  // Register a service worker hosted at the root of the
  // site using the default scope.
  navigator.serviceWorker.register("/sw.js").then(
    (registration) => {
      console.log("Service worker registration succeeded:", registration);
    },
    (error) => {
      console.error(`Service worker registration failed: ${error}`);
    }
  );
} else {
  console.error("Service workers are not supported.");
}
